@import 'InputRangeVariables';
@import 'InputRangeSlider';
@import 'InputRangeLabel';
@import 'InputRangeLabelContainer';
@import 'InputRangeTrack';

.InputRange {
    position: relative;
    width: 100%;
    height: $InputRange-slider-height;
    cursor: pointer;
}
