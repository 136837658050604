$color-primary: #ee7155;
$color-primary-text: #fff;

$color-secondary: mix($color-primary, #000, 25%);

$color-red: $color-primary;
$color-red-dark: #ff6464;
$color-orange: #f28d61;
$color-green: #adde52;
$color-green-dark: darken($color-green, 15%);
$color-blue: #6694d2;
$color-blue-dark: darken($color-blue, 15%);
$color-purple: #9f83e0;
$color-purple-dark: #9013fe;

$color-error: $color-orange;
$color-error-text: mix(#000, $color-orange, 75%);

@import '_panel';
@import '_button';
@import '_form';

$color-green-gradient: linear-gradient(135deg, #e0ea86, #99d93d);
$color-orange-gradient: linear-gradient(135deg, #fac478, #ee7155);
$color-blue-gradient: linear-gradient(135deg, #62b7c8, #6887d7);
$color-purple-gradient: linear-gradient(135deg, #c18fe0, #8f7ee1);

$color-choice-0: $color-green-gradient;
$color-choice-1: $color-orange-gradient;
$color-choice-2: $color-blue-gradient;
$color-choice-3: $color-purple-gradient;

$InputRange-fontFamily: sans-serif;
$InputRange-primaryColor: $color-primary;
$InputRange-neutralColor: #aaaaaa !default;
$InputRange-neutralLightColor: #eeeeee !default;
$InputRange-disabledColor: #cccccc !default;

// InputRange-slider
$InputRange-slider-background: $InputRange-primaryColor !default;
$InputRange-slider-border: 1px solid $InputRange-primaryColor !default;
$InputRange-slider-height: 1rem !default;
$InputRange-slider-width: 1rem !default;
$InputRange-slider-transition: transform 0.3s ease-out, box-shadow 0.3s ease-out !default;
$InputRange-sliderContainer-transition: left 0.3s ease-out !default;
$InputRange-slider--active-transform: scale(1.3) !default;
$InputRange-slider--is-disabled-background: $InputRange-disabledColor !default;
$InputRange-slider--is-disabled-border: 1px solid $InputRange-disabledColor !default;

// InputRange-label
$InputRange-label-color: $InputRange-neutralColor !default;

// InputRange-track
$InputRange-track-background: $InputRange-neutralLightColor !default;
$InputRange-track-height: 0.3rem !default;
$inputRange-track-transition: left 0.3s ease-out, width 0.3s ease-out !default;
$InputRange-track--active-background: $InputRange-primaryColor !default;
$InputRange-track--is-disabled-background: $InputRange-neutralLightColor !default;
