@import '../../style/_vars';

.lobby {
    margin-top: 15vh;

    @media screen and (min-width: 768px) {
        margin-top: 30vh;
    }
}

.joinComponent {
    position: fixed;
    top: 50%;
    left: 50%;
    animation: appear 400ms ease-in-out;
    transform: translate(-50%, -50%);
    will-change: transform;
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5) rotateX(70deg)
            perspective(600px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.logo {
    margin-bottom: 40px;
    text-align: center;
}

.panes {
    max-width: 1024px;
    margin: auto;

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
    }
}

.pane {
    display: flex;
    width: 100%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    margin: 5vw 1vw;
    border: none;
    -webkit-appearance: none;
    border-radius: 10px;
    box-shadow: 0 5px 25px rgba(#000, 0.1);
    color: #fff;
    cursor: pointer;
    font-size: 60px;
    font-weight: bolder;
    opacity: 0.85;
    text-transform: uppercase;
    transition: all ease-in-out 250ms;
    will-change: transform;

    @media screen and (min-width: 768px) {
        width: 50%;
        margin: 0 1vw;
    }

    &:hover {
        opacity: 1;
        text-shadow: 0 1px 2px rgba(#000, 0.3);
    }
}

.create {
    $transform: perspective(600px) rotateY(20deg) rotateX(5deg);

    background: $color-green-dark;
    transform: $transform;

    &:hover {
        background: $color-blue;
        transform: $transform scale(1.15);
    }

    .joinView & {
        transform: $transform translateX(-85%);
    }
}

.join {
    $transform: perspective(600px) rotateY(-20deg) rotateX(5deg);

    background: $color-purple-dark;
    transform: $transform;

    &:hover {
        background: $color-red;
        transform: $transform scale(1.15);
    }

    .joinView & {
        transform: $transform translateX(85%);
    }
}
