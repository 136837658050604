@import '../../style/_vars';

.radiogroup {
    margin: 0 0 2rem;
}

.title {
    @extend %label;
}

.values {
    display: flex;
    border: solid 3px #000;
    border-radius: 8px;
}

.vertical {
    flex-direction: column;

    .value {
        border-right: none;
        border-bottom: solid 3px #000;

        &:last-child {
            border-bottom: none;
        }
    }
}

.value {
    position: relative;
    display: flex;
    overflow: hidden;
    flex: 1;
    padding: 0;
    margin: 0;
    border-right: solid 3px #000;
    cursor: pointer;

    &:last-child {
        border-right: none;
    }

    span {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        font-size: 20px;
        text-transform: uppercase;
        transition: all 100ms ease-in-out;

        &:hover {
            background-color: rgba(#000, 0.2);
        }
    }

    input[type='radio'] {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        visibility: hidden;

        &:checked + span {
            background: #000;
            color: #fff;
        }
    }
}
