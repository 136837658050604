@import '../../style/vars';

.panels {
    display: table;
    padding: 0;
    margin: 0 auto 25px;
    font-size: 24px;
    list-style: none;
}

.panel {
    display: inline-block;
    margin: 0 5px;
    font-weight: bold;
    text-transform: uppercase;

    > * {
        display: block;
        padding: 5px 0;
        margin: 0 35px;
    }

    &.active > * {
        border-bottom: solid 4px #000;
    }
}

.tab {
    display: none;

    &.active {
        display: block;
    }
}
