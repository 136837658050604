@import '../../style/vars';

@mixin button-color($color) {
    border-color: $color;
    background-color: darken($color, 10%);

    &:hover {
        background-color: darken($color, 15%);
    }

    &:active {
        background-color: darken($color, 20%);
    }
}

.button {
    @extend %button;

    &.center {
        display: table;
        margin-right: auto;
        margin-left: auto;
    }
}

.red {
    @include button-color($color-red);
    text-shadow: 0 0 2px darken($color-red, 50%);
}

.green {
    background: linear-gradient(90deg, #bee263, #8ad62d);
    color: #000;
}

.small {
    padding: 5px 10px;
    border-width: 2px;
    font-size: 12px;
}

.purple {
    background: linear-gradient(90deg, #a72aff, #6425f8);
}
