@import '../../style/_vars';

.form {
    input {
        display: block;
        width: 100%;
    }
}

.panel {
    @extend %panel;
}
