@import '../../style/vars';

.play {
    padding-top: 35px;
    text-align: center;

    h2 {
        margin: 0 0 2rem;
    }
}

.code {
    position: fixed;
    top: 5px;
    left: 5px;
}

.mode {
    margin: 20px 0;
}

.finished {
    margin: 0 0 30px;

    strong {
        padding: 5px 10px;
        background: $color-blue;
        border-radius: 4px;
        color: #fff;
        font-size: 0.7em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
}

.keyboard {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;

    span {
        position: absolute;
        color: #fff;
        font-size: 50px;

        &[data-choice='0'] {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &[data-choice='1'] {
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &[data-choice='2'] {
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        &[data-choice='3'] {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.colors {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;

    a {
        position: absolute;
        width: 100px;
        height: 100px;
        background: #fff;
        border-radius: 50px;

        &[data-choice='0'] {
            top: 0;
            left: 50%;
            background: $color-choice-0;
            transform: translateX(-50%);
        }

        &[data-choice='1'] {
            top: 50%;
            left: 0;
            background: $color-choice-1;
            transform: translateY(-50%);
        }

        &[data-choice='2'] {
            top: 50%;
            right: 0;
            background: $color-choice-2;
            transform: translateY(-50%);
        }

        &[data-choice='3'] {
            bottom: 0;
            left: 50%;
            background: $color-choice-3;
            transform: translateX(-50%);
        }
    }
}

.leave {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}

.voted {
    position: fixed;
    z-index: 90;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#000, 0.85);
    color: #fff;
    font-weight: bold;
    text-align: center;
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.song {
    display: flex;
    align-items: center;
    text-align: left;
}

.cover {
    display: block;
    width: 60px;
    height: 60px;
    flex: none;
    margin-right: 20px;
}

.name {
    margin: 0;
    font-weight: bold;
}

.artists {
    font-size: 0.7rem;
}

.bars {
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.join {
    margin: 0 0 40px;
    color: $color-purple-dark;
    font-size: 80px;
    letter-spacing: 5px;
    text-align: center;

    small {
        display: block;
        margin: 0 0 20px;
        color: #000;
        font-family: 'Dosis';
        font-size: 24px;
        letter-spacing: normal;
        text-transform: uppercase;
    }
}
