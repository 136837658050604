.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.9);
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.code {
    width: 100%;
}

.canvas {
    width: 100%;
}
