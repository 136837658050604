.countdown {
    font-size: 200px;
    text-align: center;
    text-shadow: 0 2px 10px black;
}

.dev {
    position: fixed;
    z-index: 10000;
    top: 10px;
    left: 10px;
}
