.InputRange-track {
    position: relative;
    display: block;
    height: $InputRange-track-height;
    background: $InputRange-track-background;
    border-radius: $InputRange-track-height;
    transition: $inputRange-track-transition;

    .InputRange.is-disabled & {
        background: $InputRange-track--is-disabled-background;
    }
}

.InputRange-track--container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -0.5 * $InputRange-track-height;
}

.InputRange-track--active {
    background: $InputRange-track--active-background;
}
