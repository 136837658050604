@import '../../style/vars';

.swiper {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background: rgba(#fdfcf7, 0.85);
    color: #fff;
    font-size: 5rem;
    vertical-align: middle;
}

.arrows {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;

    span {
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        color: #fff;
        font-size: 50px;
        line-height: 100px;

        &[data-choice='0'] {
            top: 0;
            left: 50%;
            background: $color-choice-0;
            transform: translateX(-50%);
        }

        &[data-choice='1'] {
            top: 50%;
            left: 0;
            background: $color-choice-1;
            transform: translateY(-50%);
        }

        &[data-choice='2'] {
            top: 50%;
            right: 0;
            background: $color-choice-2;
            transform: translateY(-50%);
        }

        &[data-choice='3'] {
            bottom: 0;
            left: 50%;
            background: $color-choice-3;
            transform: translateX(-50%);
        }
    }
}
