.list {
    padding: 0;
    list-style: none;
}

.user {
    position: relative;
    display: block;
    margin: 0 0 10px;
}

.points {
    position: absolute;
    top: 50%;
    right: 0;
    color: #fc0;
    font-size: 1.5rem;
    font-weight: bold;
    transform: translateY(-50%);
}
