.InputRange-slider {
    position: absolute;
    top: 50%;
    display: block;
    width: $InputRange-slider-width;
    height: $InputRange-slider-height;
    margin-top: $InputRange-slider-height / -2 + $InputRange-track-height / -2;
    margin-left: $InputRange-slider-width / -2;
    border: $InputRange-slider-border;
    appearance: none;
    background: $InputRange-slider-background;
    border-radius: 100%;
    cursor: pointer;
    outline: none;
    transition: $InputRange-slider-transition;

    &:active {
        transform: $InputRange-slider--active-transform;
    }

    &:focus {
        box-shadow: 0 0 0 5px transparentize($InputRange-slider-background, 0.8);
    }

    .InputRange.is-disabled & {
        border: $InputRange-slider--is-disabled-border;
        background: $InputRange-slider--is-disabled-background;
        box-shadow: none;
        transform: none;
    }
}

.InputRange-sliderContainer {
    transition: $InputRange-sliderContainer-transition;
}
