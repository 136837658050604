@import '../../style/_vars';

.select {
    position: relative;
    display: block;
    margin: 0 0 2rem;
}

.input {
    @extend %input;

    display: block;
    min-height: 42px;
    text-align: center;

    &:after {
        position: absolute;
        top: 50%;
        right: 15px;
        content: '\25BE';
        transform: translateY(-50%);
    }
}

.element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.placeholder {
    @extend %placeholder;

    line-height: 16px;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.hide {
    opacity: 0;
    transform: translateY(0.5rem);
}
