%button {
    display: inline-block;
    min-width: 130px;
    padding: 15px 20px;
    border: none;
    -webkit-appearance: none;
    background: #000;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    outline: none;
    outline: none;
    text-align: center;
    text-transform: uppercase;
    transition: opacity 150ms linear, border 150ms linear;

    &:hover,
    &:focus {
        background: #659fcf;
    }

    &:active {
        background: #af89e0;
    }

    &[disabled] {
        border-color: #aaa;
        background: #aaa;
        box-shadow: inset 0 1px 4px rgba(#000, 0.2);
        color: #444;
        cursor: not-allowed;
        opacity: 0.5;
        text-shadow: 0 1px 0px rgba(#fff, 0.5) !important;
    }

    &.small {
        min-width: auto;
        padding: 8px 10px;
        border-width: 2px;
        font-size: 12px;
    }
}

@keyframes button-hover {
    0% {
        background-color: #659fcf;
    }

    25% {
        background-color: #b5e05a;
    }

    50% {
        background-color: #ad88e1;
    }

    75% {
        background-color: #f28a60;
    }

    100% {
        background-color: #659fcf;
    }
}
