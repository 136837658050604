@import '../../style/vars';

.container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.9);
    color: $color-primary-text;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    text-shadow: 0 1px 2px black;
    z-index: 1;
}

.modal {
    width: 100%;
    padding: 10px;
    overflow-y: auto;
    max-height: 100%;

    @media all and (min-width: 600px) {
        padding: 60px;
    }

    h1,
    h2,
    h3,
    h4 {
        font-weight: 100;
        margin: 0 0 20px;
    }
}
