$panel-padding-x: 2rem;
$panel-padding-y: 2rem;
$panel-border-radius: 3px;

%panel {
    width: 380px;
    margin: 0 auto 2rem;

    hr {
        height: 3px;
        padding: 0;
        margin: 1rem 0;
        border: none;
        background: rgba(#fff, 0.1);
        border-radius: 1px;
    }

    nav {
        margin: 0 (-$panel-padding-x) $panel-padding-y;

        &:first-child {
            margin-top: (-$panel-padding-y);

            li:first-child {
                border-radius: $panel-border-radius $panel-border-radius 0 0;

                > a {
                    border-radius: $panel-border-radius $panel-border-radius 0 0;
                }

                &:before {
                    display: none;
                }
            }
        }

        &:last-child {
            margin-bottom: (-$panel-padding-y);

            li:last-child {
                border-radius: 0 0 $panel-border-radius $panel-border-radius;

                > a {
                    border-radius: 0 0 $panel-border-radius $panel-border-radius;
                }

                &:last-of-type:after {
                    display: none;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        li {
            padding: 10px $panel-padding-x;
            border-top: solid 1px rgba(#fff, 0.1);
            border-bottom: solid 1px rgba(#000, 0.5);

            &:first-child {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }

            > a {
                display: block;
                transition: none;

                &:only-child {
                    padding: 10px $panel-padding-x;
                    margin: -10px (-$panel-padding-x);
                }

                &:hover {
                    background: $color-primary;
                    color: #fff;
                    text-shadow: 0 1px 1px #000;
                }

                &:active {
                    background: darken($color-primary, 10%);
                }
            }
        }
    }
}
