@import '../../style/_vars';

.error {
    position: relative;
    padding-left: 2rem;
    margin: 0 0 2rem;
    color: $color-primary;
    font-weight: bold;
    line-height: 1.5;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: $color-primary;
        border-radius: 1rem;
        color: $color-primary-text;
        content: '!';
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        text-shadow: 0 0 1px #000;
    }
}
