@import '../../style/_vars';

.quickfire {
    padding: 50px 0 0;
}

.code {
    position: fixed;
    top: 5px;
    left: 5px;
}

.players {
    position: fixed;
    bottom: 5px;
    left: 5px;
    font-size: 16px;
    font-weight: 800;
    text-shadow: 0 0 3px white;
    text-transform: uppercase;

    &.empty {
        color: $color-red;
    }

    span {
        font-size: 0.75em;
        opacity: 0.75;
    }
}
