@import '../../style/vars';

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    animation: fadein 300ms;
    animation-fill-mode: forwards;
    opacity: 0;

    &.overlay {
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        background: linear-gradient(-90deg, #fcf9f1, #fdfdf8);
    }

    p {
        margin: 1em 0 0;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.75;
    }
}
