.InputRange-label {
    color: $InputRange-label-color;
    font-family: $InputRange-fontFamily;
    font-size: 0.8rem;
    white-space: nowrap;
}

.InputRange-label--min,
.InputRange-label--max {
    position: absolute;
    bottom: -1.4rem;
}

.InputRange-label--min {
    left: 0;
}

.InputRange-label--max {
    right: 0;
}

.InputRange-label--value {
    position: absolute;
    top: -1.8rem;
}
