@import '../../style/_vars';

.rings {
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: opacity 400ms ease-in-out;
    pointer-events: none;
}

.ring {
    position: absolute;
    z-index: -1;
    width: 84vw;
    height: 84vw;
    border: solid 100px;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    background-size: contain;
    border-radius: 50%;
    opacity: 0.39;
    will-change: transform;

    &:nth-child(1) {
        bottom: -32vh;
        left: -51vw;
        border-color: $color-orange;
        animation: ring-animation-1 60s infinite;
    }

    &:nth-child(2) {
        bottom: -88vh;
        left: -24vw;
        border-color: $color-green;
        animation: ring-animation-2 50s infinite;
    }

    &:nth-child(3) {
        right: -13vw;
        bottom: -98vh;
        border-color: $color-blue;
        animation: ring-animation-3 40s infinite;
    }

    &:nth-child(4) {
        right: -56vw;
        bottom: -45vw;
        border-color: $color-purple;
        animation: ring-animation-4 30s infinite;
    }
}

@keyframes ring-animation-1 {
    0% {
        transform: scale(0.8) translate(0, 0);
    }

    25% {
        transform: translate(0.5%, 1.5%);
    }

    50% {
        transform: scale(1.2) translate(-0.5%, 1%);
    }

    75% {
        transform: translate(2%, -1.25%);
    }

    100% {
        transform: scale(0.8) translate(0, 0);
    }
}

@keyframes ring-animation-2 {
    0% {
        transform: scale(1) translate(0, 0);
    }

    25% {
        transform: scale(0.8) translate(-2.5%, -1.5%);
    }

    50% {
        transform: translate(1%, 1.25%);
    }

    75% {
        transform: scale(1.2) translate(0, 0);
    }

    100% {
        transform: scale(1) translate(0, 0);
    }
}

@keyframes ring-animation-3 {
    0% {
        transform: scale(1.2) translate(0, 0);
    }

    25% {
        transform: translate(1.5%, -1%);
    }

    50% {
        transform: scale(0.8) translate(-0.5%, 1.25%);
    }

    75% {
        transform: translate(0.75%, 1.5%);
    }

    100% {
        transform: scale(1.2) translate(0, 0);
    }
}

@keyframes ring-animation-4 {
    0% {
        transform: scale(0.9) translate(0, 0);
    }

    25% {
        transform: translate(2.5%, 5%);
    }

    50% {
        transform: scale(1.1) translate(2%, 2.5%);
    }

    75% {
        transform: translate(2.5%, 5%);
    }

    100% {
        transform: scale(0.9) translate(0, 0);
    }
}
