.track {
    font-size: 1.5rem;

    &.standard {
        text-align: center;
    }
}

.imageWrapper {
    position: relative;
    text-align: center;
}

.image,
.shadow {
    z-index: 2;
    display: block;
    max-width: 100%;
    max-height: 60vh;

    .standard & {
        margin: 0 auto 40px;
    }

    .list & {
        max-height: 60px;
        margin-right: 25px;
    }
}

.shadow {
    position: absolute;
    z-index: -1;
    left: 50%;
    margin-bottom: 0 !important;
    filter: blur(15px);
    transform: translateX(-50%);

    .list & {
        display: none;
    }
}

.name {
    .list & {
        margin: 0 0 0.5rem;
    }
}

.artists {
    font-weight: bold;
    text-transform: uppercase;
}
