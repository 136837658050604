.scoreboard {
    max-width: 60vw;
    margin: auto;
    text-align: center;
}

.top {
    display: flex;
    padding: 0;
    margin: 35px 0 0;
    list-style: none;

    li {
        position: relative;
        flex: 0 0 33.33%;

        &:before {
            position: absolute;
            top: -35px;
            left: 50%;
            width: 25px;
            height: 25px;
            background: #000;
            border-radius: 50%;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            line-height: 25px;
            text-align: center;
            transform: translateX(-50%);
        }

        &:first-child:before {
            content: '1';
        }

        &:nth-child(2):before {
            content: '2';
        }

        &:nth-child(3):before {
            content: '3';
        }

        > div {
            display: block;
            text-align: center;

            img {
                display: block;
                margin: 0 auto 10px !important;
            }
        }
    }
}

.rest {
    display: flex;
    flex-wrap: wrap;
    margin: 100px 0;
    list-style: none;

    li {
        width: (100%/3);
        flex: 0 0 auto;
        padding: 10px 20px;
    }
}
