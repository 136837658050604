@import '../../style/_vars';

.player {
    margin: 0 0 20px;
}

.song {
    display: flex;
    align-items: center;
}

.cover {
    display: block;
    width: 60px;
    height: 60px;
    flex: none;
    margin-right: 20px;
}

.name {
    margin: 0;
    font-weight: bold;
}

.artists {
    font-size: 0.7rem;
}

.audio {
    display: block;
    margin: 10px 0 0;
}
