@import '../../style/_vars';

.game {
    table {
        width: 100%;
        text-align: center;

        td {
            width: 33.3%;
        }
    }
}

.amountOfPlayers {
    position: fixed;
    bottom: 2.5rem;
    left: 2.5rem;
    font-size: 2rem;

    p {
        margin: 0;
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    strong {
        color: $color-purple-dark;

        &:first-child {
            color: $color-green-dark;
        }
    }
}

.round {
    position: fixed;
    top: 20px;
    left: 20px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}

.code {
    margin: 0 0 40px;
    color: $color-purple-dark;
    font-size: 80px;
    letter-spacing: 5px;
    text-align: center;

    small {
        display: block;
        margin: 0 0 20px;
        color: #000;
        font-family: 'Dosis';
        font-size: 24px;
        letter-spacing: normal;
        text-transform: uppercase;
    }
}

@keyframes fastest {
    0% {
        opacity: 0;
        transform: scale(3);
    }
    // 33% {
    //     transform: scale(2);
    // }
    100% {
        transform: none;
    }
}

.fastest {
    position: fixed;
    top: 2.5rem;
    right: 250px;
    left: 250px;
    max-width: 670px;
    margin: auto;
    text-align: center;

    > p {
        margin-bottom: 50px;
        font-weight: bold;
    }

    ul {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            position: relative;
            flex: 0 0 33.33%;
            animation: fastest 250ms ease-in-out;
            will-change: transform;

            &::before {
                position: absolute;
                top: -35px;
                left: 50%;
                width: 25px;
                height: 25px;
                background: #000;
                border-radius: 50%;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                line-height: 25px;
                text-align: center;
                transform: translateX(-50%);
            }

            &:first-child::before {
                content: '1';
            }

            &:nth-child(2)::before {
                content: '2';
            }

            &:nth-child(3)::before {
                content: '3';
            }

            > div {
                display: block;
                text-align: center;

                img {
                    display: block;
                    margin: 0 auto 10px !important;
                }
            }
        }
    }
}

.countdown {
    font-size: 5rem;
    text-align: center;
}

.track {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.start {
    display: table;
    margin: 0 auto 50px;
}

.players {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 250px;

    > li {
        flex: 0 0 250px;
        margin: 0 30px 30px 0;
    }
}

.play {
    display: table;
    margin: 20px auto;
    text-transform: uppercase;
}

.question {
    position: fixed;
    top: 2.5rem;
    left: 2.5rem;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.selections {
    position: fixed;
    top: 180px;
    bottom: 60px;
    left: 80px;
    overflow: hidden;
    padding: 0;
    list-style: none;

    > li {
        margin: 10px;
    }
}

.flex {
    display: flex;
    margin: 0 -10px;

    ul {
        flex: 0 0 25%;
        padding-right: 20px;
    }

    > div {
        position: relative;
        flex: 0 0 75%;
    }
}

.countdownOverlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: $color-red-dark;
    font-size: 25vh;
    font-weight: bold;
}

.scores {
    max-width: 60vw;
    margin: auto;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.indicators {
    display: flex;
    max-width: 400px;
    margin: 0 auto 100px;
    color: #9b9b9b;
    list-style: none;

    li {
        flex: 0;
        margin: 0 50px;
        font-size: 24px;
        white-space: nowrap;
    }
}

.activeIndicator {
    border-bottom: solid 3px #000;
    color: #000;
}

.fadeScaleEnter {
    opacity: 0;
    transform: scale(0.2);
}

.fadeScaleEnterActive {
    opacity: 1;
    transform: none;
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.fadeScaleLeave {
    opacity: 1;
    transform: none;
}

.fadeScaleLeaveActive {
    opacity: 0;
    transform: scale(2);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.slideEnter {
    opacity: 0;
    transform: translateX(100%) scale(0.25);
}

.slideEnterActive {
    opacity: 1;
    transform: none;
    transition: opacity 250ms ease-in-out, transform 500ms ease-in-out;
}

.slideLeave {
    opacity: 1;
    transform: none;
}

.slideLeaveActive {
    opacity: 0;
    transform: translateX(-100%) scale(0.25);
    transition: opacity 250ms ease-in-out, transform 500ms ease-in-out;
}
