@import '../../style/vars';

.bars {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    animation: fadein 300ms;
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;
}

.text {
    margin: 0;
    position: absolute;
    top: 50%;
    background: rgba(#fdfbf4, 0.8);
    text-transform: uppercase;
    transform: translateY(-50%);
    padding: 0.25rem 0.75rem;
    border-radius: 50px;
    font-size: 14px;

    strong {
        font-weight: 900;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.75;
    }
}
