@import '../../style/vars';
@import '../../style/assets';

.choices {
    position: absolute;
    top: 27vh;
    bottom: 60px;
    left: calc(50% - 38vh);
    width: 76vh;
    animation: fadeIn 250ms ease-in-out;
    transition: all 200ms ease-in-out;
    will-change: transform;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.transition {
    opacity: 0;
    transform: scale(2);
}

$stagger: 60ms;

.choice {
    position: absolute;
    animation-duration: 600ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.88, -0.21, 0.24, 1.26);
    opacity: 0;

    &:first-child {
        top: 0;
        left: 50%;
        animation-name: animateRing1;
        transform: translateX(-50%);
    }

    &:nth-child(2) {
        top: 50%;
        left: 0;
        animation-delay: $stagger * 2;
        animation-name: animateRing2;
        transform: translateY(-50%);
    }

    &:nth-child(3) {
        top: 50%;
        right: 0;
        animation-delay: $stagger * 3;
        animation-name: animateRing2;
        transform: translateY(-50%);
    }

    &:nth-child(4) {
        bottom: 0;
        left: 50%;
        animation-delay: $stagger * 1;
        animation-name: animateRing1;
        transform: translateX(-50%);
    }
}

@keyframes animateRing1 {
    0% {
        transform: translateX(-50%) scale(0.2);
    }

    100% {
        opacity: 1;
        transform: translateX(-50%);
    }
}

@keyframes animateRing2 {
    0% {
        transform: translateY(-50%) scale(0.2);
    }

    100% {
        opacity: 1;
        transform: translateY(-50%);
    }
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulsateCountdown {
    0% {
        transform: translate(-50%, -50%) rotate(-90deg) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) rotate(-90deg) scale(1.15);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-90deg) scale(1);
    }
}

.countdown {
    @extend %progress-bg;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 85px;
    height: 85px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    transform: translate(-50%, -50%) rotate(-90deg);
    transition: background 250ms linear;

    &.alert {
        @extend %progress-bg-alert;

        animation: pulsateCountdown 500ms infinite;
    }
}

@keyframes circle-animation {
    to {
        stroke-dashoffset: 0;
    }
}

.circle {
    z-index: 1;
    stroke-dasharray: 245;
    stroke-dashoffset: 245;
    will-change: transform;

    circle {
        transition: stroke 250ms linear;
    }
}

.circleShadow {
    position: absolute;
    left: -20px;
    filter: blur(5px);
    opacity: 0.4;
}

.animation {
    animation: circle-animation 10000ms linear forwards;
}
