@import '../../style/_vars';

.wrapper {
    display: grid;
    margin: 5vw 2vw;
    grid-gap: 2vw;
    grid-template-columns: repeat(2, 1fr);
}

.alternative {
    display: flex;
    min-height: 25vh;
    align-items: center;
    justify-content: center;
    padding: 2vw;
    background: rgba(#000, 0.1);
    font-size: calc(3vw + 1vh);

    &[data-choice='0'] {
        background: $color-choice-0;
    }

    &[data-choice='1'] {
        background: $color-choice-1;
    }

    &[data-choice='2'] {
        background: $color-choice-2;
    }

    &[data-choice='3'] {
        background: $color-choice-3;
    }
}
