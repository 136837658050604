@import '../../style/_vars';

.songselector {
    max-width: 400px;
    margin: auto;

    ul {
        list-style: none;
    }
}

.search {
    display: flex;
    width: 100%;

    input {
        flex: 1;
    }

    button {
        flex: none;
    }
}

.results {
    margin: 20px 0 0;
}

.button {
    width: 100%;
    border: none;
    -webkit-appearance: none;
    background: none;
    box-shadow: none;
    text-align: left;

    &[disabled] {
        opacity: 0.5;
    }
}

.wrapper {
    display: flex;
    align-items: center;
}

.cover {
    display: block;
    width: 60px;
    height: 60px;
    flex: none;
}

.info {
    margin-left: 10px;
    line-height: 1;
}

.name {
    margin: 0;
    font-weight: bold;
}

.artists {
    margin: 5px 0 0;
    font-size: 0.7em;
}
