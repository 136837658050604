@import '../../style/_vars';

.user {
    font-size: 2.5vh;
    text-align: center;
    transition: opacity 1000ms linear;
}

.small {
    display: flex;
    align-items: center;
    text-align: left;

    .avatar {
        height: 5vh;
        min-height: 40px;
        align-items: center;
        margin: 0 20px 0 0;
    }

    .name {
        margin: 0;
    }
}

.avatar {
    display: block;
    margin: 0 auto 10px;
    transition: filter 1000ms linear, -webkit-filter 1000ms linear;
}

.name {
    width: 100%;
    margin: 0 0 1em;
    font-weight: bold;
}

.dimmed {
    opacity: 0.25;

    .avatar {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
}

.points {
    margin: 0;
    color: $color-purple;
    font-weight: bold;

    .noAvatar & {
        padding-left: 20px;
    }
}
