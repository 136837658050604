@import '_vars';
@import 'vendor/react-input-range/InputRange';
@import url('https://fonts.googleapis.com/css?family=Dosis:400,500,700,800|Fjalla+One');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    height: 100%;
}

#root {
    position: relative;
    z-index: 1;
    height: 100%;
    overflow-x: hidden;
}

body {
    background: #fdfbf4;
    background: linear-gradient(135deg, #f9f7f0, #fdfdf7);
    color: #000;
    font-family: 'Dosis', sans-serif;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
}

button,
input {
    font: inherit;
    appearance: none;
    border: none;
    background: none;
}

h1,
h2,
h3,
h4 {
    font-family: 'Fjalla One', sans-serif;
}

h1 {
    font-size: 80px;
    font-weight: normal;
    text-transform: uppercase;
}

p {
    margin: 0 0 1rem;
}

p:last-child {
    margin-bottom: 0;
}

pre {
    padding: 3px;
    margin: 10px 0;
    background: #eee;
    border-radius: 3px;
    color: #777;
    font-size: 10px;
}

a {
    cursor: pointer;
    text-decoration: none;
    transition: color 150ms linear, border 150ms linear;

    &.is-active,
    &:hover {
        border-color: $color-primary;
        color: $color-primary;
    }
}

a.back,
button.back {
    display: table;
    margin: 1rem auto;
    color: #000;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: transform 200ms ease-in-out;
    will-change: transform;

    &::before {
        display: inline-block;
        margin-right: 10px;
        content: '➜';
        transform: rotate(180deg);
    }

    &:hover {
        transform: translateX(-5px);
    }
}

.button {
    @extend %button;
}

input {
    &[type='text'],
    &[type='email'],
    &[type='number'],
    &[type='password'] {
        @extend %input;
    }
}

label {
    @extend %label;
}

hr {
    height: 3px;
    padding: 0;
    margin: 25px 0;
    border: none;
    background: rgba(#fff, 0.25);
    border-radius: 1px;
}

// TODO: REMOVE THIS RULE!
a[role='button'] {
    @extend %button;
}

code {
    color: #9013fe;
    font-family: 'Fjalla One';
    font-weight: bold;
}
