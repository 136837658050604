%input {
    display: inline-block;
    padding: 10px 20px;
    border: solid 3px #000;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 8px;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    text-align: left;
    transition: opacity 150ms linear, color 150ms linear, border 150ms linear;

    &:focus {
        border-color: #ee7356;
        color: #8f7ee1;
    }

    &[disabled] {
        color: #888;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

%label {
    display: block;
    margin: 0 0 1rem;
    font-size: 30px;
    font-weight: bold;
    opacity: 1;
    text-align: center;
    transform: translateY(0);
    transition: opacity 150ms linear, transform 150ms ease-out;
}

%placeholder {
    color: rgba(#000, 0.5) !important;
    font-weight: 400;
}

::-webkit-input-placeholder {
    @extend %placeholder;
}
