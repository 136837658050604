@import '~style/vars';

.join {
    margin: auto;
}

.code {
    width: 250px !important;
    color: $color-purple-dark !important;
    font-family: 'Fjalla One', sans-serif;
    font-size: 40px !important;
    letter-spacing: 0.2em;
    text-align: center !important;
    text-transform: uppercase;
}
