.mainMenu {
    position: fixed;
    top: 10px;
    left: 10px;
}

.newGame {
    text-align: center;
    margin: 0 0 80px;
}
