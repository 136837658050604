@import '../../style/vars';

.auth {
    transition: opacity 150ms linear;
}

.unauthenticated {
    max-width: 286px;
    margin: 0 auto;
    text-align: center;
}

.error {
    display: block;
    padding: 10px 20px;
    background: $color-error;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(#fff, 0.5);
    color: $color-error-text;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.5;
    text-shadow: 0 1px 0px rgba(#fff, 0.4);
    text-transform: uppercase;
}

.name {
    color: #000;
}

.authenticated {
    position: fixed;
    top: 10px;
    right: 20px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;

    button {
        padding: 5px 10px;
        margin-left: 10px;
        border-width: 2px;
        font-size: 12px;
    }

    .logo {
        display: none;
    }

    @media all and (min-width: 600px) {
        top: 60px;
        right: 80px;
    }
}

.providers {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    list-style: none;

    > li {
        margin: 0 20px;
    }

    a {
        display: block;
    }
}

.signup {
    display: table;
    margin: 25px auto 0;
    border-bottom: solid 2px #000;
    font-weight: bold;
}

.authing {
    opacity: 0.5;
}

.avatar {
    text-align: center;
}

.name {
    margin-right: 10px;
    font-size: 20px;
}

.user {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.logout {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #000;
    border-bottom-left-radius: 4px;
    color: #fff;
}
