@import '../../style/_vars';

.loading {
    position: relative;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.genres {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
}

.genre {
    flex: 0 0 50%;
    padding: 5px;
    text-align: center;

    a {
        font-size: 24px;
        font-weight: 900;
        text-transform: uppercase;
    }
}

.genresLoading {
    li {
        @extend %label;

        flex: 1;
        animation: loading 1000ms;
        animation-iteration-count: infinite;
        color: #fff;
        text-align: center;
    }
}

.activeGenre {
    color: $color-primary;
    font-weight: 700;
}

.play {
    margin-top: 40px;
}

@keyframes loading {
    0% {
        color: #fff;
    }

    50% {
        color: #777;
    }

    100% {
        color: #fff;
    }
}

.error {
    padding: 10px 20px;
    background-color: $color-red;
    border-radius: 4px;
    color: #fff;
    text-align: center;
}

.allGenres {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    button {
        text-transform: uppercase;

        &:not(.activeGenre) {
            color: #fff;
        }
    }
}

.selectedGenre {
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    color: $color-primary;
}

.genreFilter {
    display: none !important;
}
