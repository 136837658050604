@import '../../style/vars';

.activePlaylist {
    position: relative;
    color: $color-orange;

    &:before {
        position: absolute;
        left: -10px;
        content: '✓';
        transform: translateX(-100%);
    }
}

.input {
    display: flex;

    input {
        height: 60px;
    }

    button {
        flex: 0;
        align-self: flex-end;
        margin: 0 0 2rem 1rem;
        white-space: nowrap;
    }
}

.playlists {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: 0 -100px;
    list-style: none;

    li {
        width: 50%;
        flex: 0 0 auto;
        padding: 0 20px;
    }

    a {
        display: block;
        margin: 0 0 0.5rem;
        font-weight: bold;
    }
}

.dimmed {
    opacity: 0.33;
}

.genres,
.categories {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 65vw;
    display: grid;
    grid-template-columns: repeat(5, minmax(10px, 1fr));
    gap: 20px;
    align-items: start;
}

.genrePlaylist,
.category {
    transition: opacity 150ms linear;

    img {
        max-width: 100%;
    }
}
