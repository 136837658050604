@import '../../style/assets';

.ring {
    position: relative;
    display: inline-flex;
    min-width: 18vh;
    max-width: 22vh;
    height: 100%;
    justify-content: center;
    padding: 30px;
    margin: 30px;
    background-size: cover;
    font-family: 'Fjalla One';
    font-size: 2.5vh;
    font-weight: 400;
    line-height: 3vh;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;

    &:before {
        padding-top: 100%;
        content: '';
        float: left;
    }

    &:after {
        position: absolute;
        z-index: -1;
        top: 25%;
        width: 100%;
        height: 100%;
        background-size: cover;
        content: '';
        filter: blur(8px);
        opacity: 0.6;
    }

    span {
        display: block;
        min-height: 100%;
        align-self: center;
        color: #000;
        font-weight: bold;
    }
}

.orange,
.orange:after {
    @extend %ring-orange;
}

.blue,
.blue:after {
    @extend %ring-blue;
}

.green,
.green:after {
    @extend %ring-green;
}

.purple,
.purple:after {
    @extend %ring-purple;
}
