.app {
    padding: 10px;

    @media all and (min-width: 600px) {
        padding: 60px;
    }
}

.video {
    position: fixed;
    z-index: -3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    video {
        position: absolute;
        z-index: -2;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#111, 0.97);
        content: '';
    }
}
